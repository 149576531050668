import { Injectable, signal } from '@angular/core';
import { SearchMode } from '../typings/search-mode';
import { Store } from '../../core/services/store.service';
import { SearchSegment } from '../typings/search-segment';
import {
    BooleanPropertyFilterInput,
    Category,
    CategoryByIdGQL,
    NumericPropertyFilterInput,
    PredefinedValueProperty,
    PredefinedValuePropertyFilterInput,
    Property,
    PropertyAggregation,
    PropertyFilterInput
} from '../../../../graphql/generated';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { filter, map, of, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';

export interface SearchState {
    mode: SearchMode;
    segment: SearchSegment | null;
    categoryId: string | null;
    itemId: string | null;
    date: string | null;
    filters: PropertyFilterInput[];
    aggregations: PropertyAggregation[]
}

@Injectable({
    providedIn: 'root'
})
export class SearchService extends Store<SearchState> {

    public readonly isActive = signal<boolean>(false);

    public readonly category = toSignal(
        this.observe('categoryId').pipe(
            switchMap(id => id
                ? this.categoryByIdGQL.watch({ id }).valueChanges.pipe(
                    map(result => result.data.categoryById as Category | null)
                )
                : of(null)
            )
        )
    );

    public readonly category$ = toObservable(this.category);

    public readonly filters = toSignal(this.category$
        .pipe(
            filter(category => !!category),
            tap(category => {
                this.set('filters', this.getPreselectedFilters(category!));
            })
        ));

    constructor(
        private readonly categoryByIdGQL: CategoryByIdGQL
    ) {
        super();
        this.initialize();
    }

    private initialize() {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        const tomorrowString = tomorrow.toISOString().split('T')[0]; // Format: YYYY-MM-DD

        this.set('mode', 'supply');
        this.set('segment', null);
        this.set('categoryId', null);
        this.set('itemId', null);
        this.set('date', tomorrowString);
        this.set('filters', []);
        this.set('aggregations', []);
    }

    public setFilter(
        filterId: string,
        type: keyof PropertyFilterInput,
        value: PredefinedValuePropertyFilterInput | NumericPropertyFilterInput | BooleanPropertyFilterInput
    ) {
        this.update('filters', filters => {
            const newFilters = filters.filter(filter => {
                const currentType = Object.keys(filter)[0] as keyof PropertyFilterInput;
                return filter[currentType]?.propertyId !== filterId;
            });

            // Check if the new value is empty or null
            const isEmpty = (value: any) => {
                if (type === 'predefined') {
                    return !(value as PredefinedValuePropertyFilterInput).predefinedValueIds?.length;
                } else if (type === 'numeric') {
                    const numericValue = value as NumericPropertyFilterInput;
                    return numericValue.minNumericValue === null && numericValue.maxNumericValue === null;
                } else if (type === 'boolean') {
                    return (value as BooleanPropertyFilterInput).booleanValue === null;
                }
                return false;
            };

            // Only add the new filter if it's not empty
            if (!isEmpty(value)) {
                newFilters.push({ [type]: value } as PropertyFilterInput);
            }

            return newFilters;
        });
    }

    public removePredefinedFilterValue(propertyId: string, valueId: string) {
        this.update('filters', filters => {
            return filters.reduce((acc, filter) => {
                if (filter.predefined?.propertyId === propertyId) {
                    const newPredefinedValueIds = filter.predefined.predefinedValueIds.filter(id => id !== valueId);
                    if (newPredefinedValueIds.length > 0) {
                        acc.push({
                            predefined: {
                                ...filter.predefined,
                                predefinedValueIds: newPredefinedValueIds
                            }
                        });
                    }
                    // If newPredefinedValueIds is empty, we don't add this filter back
                } else {
                    acc.push(filter);
                }
                return acc;
            }, [] as PropertyFilterInput[]);
        });
    }

    public removeFilter(id: string) {
        this.update('filters', filters => filters.filter(filter => {
            const type = Object.keys(filter)[0] as keyof PropertyFilterInput;
            return filter[type]?.propertyId !== id;
        }));
    }

    public hasFilter(id: string): boolean {
        return this.value('filters').some(filter => {
            const type = Object.keys(filter)[0] as keyof PropertyFilterInput;
            return filter[type]?.propertyId === id;
        });
    }

    public clearFilters() {
        this.set('filters', []);
    }

    protected getPreselectedFilters(category: Category | null): PropertyFilterInput[] {
        if (!category) return [];

        return category.properties.reduce((filters, property) => {
            const casted = property as (Property & { __typename: string });

            if (casted.__typename === 'PredefinedValueProperty') {
                const preselectedValues = (property as PredefinedValueProperty).values.filter(value => value.isPreselected);
                if (preselectedValues.length > 0) {
                    filters.push({
                        predefined: {
                            propertyId: property.id,
                            predefinedValueIds: preselectedValues.map(value => value.id)
                        }
                    });
                }
            }
            return filters;
        }, [] as PropertyFilterInput[]);
    }
}
